import "lazysizes";
import "lazysizes/plugins/parent-fit/ls.parent-fit";
import fitvids from "fitvids"; /// https://github.com/rosszurowski/fitvids NOT chirs Coyer davitrom

// always first in the stack cos of jquery

lazySizes.init();


if (document.readyState !== "loading") {
		eventHandler();
	} else {
		document.addEventListener("DOMContentLoaded", eventHandler );
}

function eventHandler(){

	fitvids();

}